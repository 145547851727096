<template>
	<div>
		<ContentHeader title="Laporan Pelayanan Klinik" subTitle="Laporan Appointment Pasien" url="laporan-pelayanan-klinik" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan Appointment Pasien</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row" v-if="toogleCabang">
              <div class="col-lg-4">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <div class="row align-items-center mt-2">
                <div class="col-lg-2">
                  <span>Jenis Periode</span>
                  <select class="custom-select form-control" v-model="jenis_periode" @change="getDataResult">
                    <option value="tanggal">Tanggal</option>
                    <!-- <option value="minggu">Minggu</option> -->
                    <option value="bulan">Bulan</option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <span>Tanggal</span>
                  <input type="date" name="" v-model="tanggal" class="form-control" @change="getDataResult">
                </div>
                <div class="col-lg-4">
                </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">NO</th>
                  <th scope="col" class="text-sm">NAMA</th>
                  <th scope="col" class="text-sm">KUNJUNGAN PERTAMA</th>
                  <th scope="col" class="text-sm">KUNJUNGAN BERIKUTNYA</th>
                  <th scope="col" class="text-sm">KETERANGAN</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data_result" :key="row.id">
                  <td class="text-sm">{{ index + 1 }}</td>
                  <td class="text-sm">{{ row.nama_pasien }}</td>
                  <td class="text-sm">{{ row.tanggal }}</td>
                  <td class="text-sm">{{ row.kunjungan_berikutnya }}</td>
                  <td class="text-sm">{{ row.status }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'

const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const total = ref('')
const cek_data = ref(false)
const memuat_data = ref(false)
const tanggal = ref('')
const jenis_periode = ref('tanggal')

const getDataResult = async () => {
    console.log(jenis_periode.value)
    data_result.value = []
    total.value = ''
    memuat_data.value = true
    cek_data.value = false
    let date = ''
    let cabang_id = ''

    if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
    } else {
        cabang_id = cabang_select_value.value.id
    }

    if (tanggal.value == '') {
        date = 'emptyParameter'
    } else {
        date = tanggal.value
    }

    let { data } = await axios.get(`api/laporan/getLaporanAppointmentPasien/${cabang_id}/${jenis_periode.value}/${date}`)
    
    if (data != 'kosong') {
        memuat_data.value = false
        cek_data.value = false
        data_result.value = data
    } else {
        cek_data.value = true
        memuat_data.value = false
        data_result.value = []
    }
}

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
    let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

    if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
        if (data != 'kosong') {
            cabang.value = data
            loading_cabang.value = false
        } else {
            cabang.value = []
            loading_cabang.value = false
        }
    }
}


onMounted(() => {
    getCabang()
    getDataResult()
})
</script>